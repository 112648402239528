import client from 'api/apollo-client';
import { useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import {
	CtaFieldsFragment,
	Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor,
	Enum_Componentinternaldefaultmoduleoptions_Padding,
	LinkBoxFieldsFragment,
	NotFoundPageFieldsFragment,
	PublicationState,
	SeoFieldsFragment
} from 'api/graphql-types';
import { GetStaticProps } from 'next';
import {
	getServerPageGetInformationCondoleance,
	getServerPageGetInformationEfroOost,
	getServerPageGetInformationGelderland,
	getServerPageGetInformationGeolink,
	getServerPageGetInformationOpOost,
	getServerPageGetInformationRegioExpres,
	getServerPageGetNotFound
} from 'api/graphql-helper';
import { Head } from 'modules/head/head';
import Layout from 'modules/layout/layout';
import { BaseModule } from 'modules/base-module/base-module';
import IntroHeader from 'modules/intro-header/intro-header';
import Linkbox from 'modules/linkbox/linkbox';
import Cta from 'modules/cta/cta';
import { getStrapiParametersFromContext } from 'utils/strapi-parameters';
import {
	initialState as settingsInitialState,
	setHideLanguageSwitcher,
	setHideSearchbar,
	setShowNeutralStyle
} from 'redux/stores/settings';
import { RootState, useAppSelector } from 'redux/store';
import { ROUTES } from 'constants/routes';
import { useEffect, useState } from 'react';
import { SUBSITES } from 'constants/subsites';

import globalStylesOost from 'styles/global-oost';
import globalStyles from 'styles/global-gelderland';
import { getSite } from 'utils/subsite';

interface INotFoundPageProps {
	notFoundPage: NotFoundPageFieldsFragment;
	initialReduxState: Partial<RootState>;
}

const NotFoundPage = (props: INotFoundPageProps): React.JSX.Element => {
	const dispatch = useDispatch();
	const [pageInformation, setPageInformation] = useState<any>();
	const router = useRouter();

	const [reactWindow, setReactWindow] = useState<any>();

	let { locale } = router;
	const settings = useAppSelector((state) => state.settings);
	const { publicationState } = settings;

	if (locale === 'pl') {
		locale = 'en';
	}

	useEffect(() => {
		getPageInformation(locale || 'nl', publicationState, setPageInformation, dispatch);

		setReactWindow(window);
	}, []);

	const seoModule = pageInformation?.seo as SeoFieldsFragment;
	const headerDefaultOptions = {
		backgroundColor: props.notFoundPage?.header
			?.backgroundColor as Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor,
		padding: 'none' as Enum_Componentinternaldefaultmoduleoptions_Padding
	};

	return (
		<Layout navbar={pageInformation?.navbar} footer={pageInformation?.footer}>
			<Head seoModule={seoModule} seoParent={pageInformation?.seo} />
			{props.notFoundPage?.header && (
				<BaseModule defaultModuleOptions={headerDefaultOptions}>
					<IntroHeader module={props.notFoundPage.header} fullPath={ROUTES.NOT_FOUND.fullPath} />
				</BaseModule>
			)}
			{props.notFoundPage?.links && (
				<BaseModule defaultModuleOptions={props.notFoundPage?.links?.defaultModuleOptions}>
					<Linkbox module={props.notFoundPage.links as LinkBoxFieldsFragment} />
				</BaseModule>
			)}
			{props.notFoundPage?.cta && (
				<BaseModule defaultModuleOptions={props.notFoundPage?.cta?.defaultModuleOptions}>
					<Cta module={props.notFoundPage.cta as CtaFieldsFragment} />
				</BaseModule>
			)}
			{(reactWindow && reactWindow?.location?.host?.includes('op-oost')) ||
			(reactWindow && reactWindow?.location?.host?.includes('efro-oost')) ? (
				<style jsx global>
					{globalStylesOost}
				</style>
			) : (
				<style jsx global>
					{globalStyles}
				</style>
			)}
		</Layout>
	);
};

export const getStaticProps: GetStaticProps = async (ctx) => {
	try {
		let { locale } = getStrapiParametersFromContext(ctx);
		const { publicationState } = getStrapiParametersFromContext(ctx);

		if (locale === 'pl') {
			locale = 'en';
		}

		const result = await getServerPageGetNotFound(
			{
				variables: {
					locale,
					publicationState
				}
			},
			client
		);
		const { notFoundPage } = result.props.data;

		return {
			props: {
				key: notFoundPage?.data?.id,
				notFoundPage: notFoundPage?.data?.attributes,
				initialReduxState: {
					settings: {
						...settingsInitialState,
						publicationState
					}
				}
			},
			revalidate: Number(process.env.REVALIDATE_TIME_IN_SECONDS)
		};
	} catch (error: any) {
		// eslint-disable-next-line no-console
		console.error('Error in 404', error);

		return {
			notFound: true
		};
	}
};

const getPageInformation = async (
	locale: string,
	publicationState: PublicationState,
	setPageInformation: (information: any) => void,
	dispatch: any
): Promise<void> => {
	const { hostname } = window.location;
	const site = getSite(hostname);

	if (site?.includes(SUBSITES.REGIO_EXPRES)) {
		dispatch(setHideLanguageSwitcher(true));
		dispatch(setHideSearchbar(true));
		const result = await getServerPageGetInformationRegioExpres(
			{
				variables: {
					locale,
					publicationState
				}
			},
			client
		);
		setPageInformation(result.props.data.regioExpresInformation?.data?.attributes);
	} else if (site?.includes(SUBSITES.OP_OOST)) {
		dispatch(setHideLanguageSwitcher(true));
		dispatch(setHideSearchbar(true));
		dispatch(setShowNeutralStyle(true));
		const result = await getServerPageGetInformationOpOost(
			{
				variables: {
					locale,
					publicationState
				}
			},
			client
		);
		setPageInformation(result.props.data.opOostInformation?.data?.attributes);
	} else if (site?.includes(SUBSITES.EFRO_OOST)) {
		dispatch(setHideLanguageSwitcher(true));
		dispatch(setHideSearchbar(true));
		dispatch(setShowNeutralStyle(true));
		const result = await getServerPageGetInformationEfroOost(
			{
				variables: {
					locale,
					publicationState
				}
			},
			client
		);
		setPageInformation(result.props.data.efroOostInformation?.data?.attributes);
	} else if (site?.includes(SUBSITES.CONDOLEANCE_REGISTER_PROVINCIE_GELDERLAND)) {
		dispatch(setHideLanguageSwitcher(true));
		dispatch(setHideSearchbar(true));
		const result = await getServerPageGetInformationCondoleance(
			{
				variables: {
					locale,
					publicationState
				}
			},
			client
		);
		setPageInformation(result.props.data.condoleanceInformation?.data?.attributes);
	} else if (site?.includes(SUBSITES.GEO_LINK)) {
		dispatch(setHideLanguageSwitcher(true));
		dispatch(setHideSearchbar(true));
		const result = await getServerPageGetInformationGeolink(
			{
				variables: {
					locale,
					publicationState
				}
			},
			client
		);
		setPageInformation(result.props.data.geolinkInformation?.data?.attributes);
	} else {
		dispatch(setHideLanguageSwitcher(true));
		dispatch(setHideSearchbar(true));
		const result = await getServerPageGetInformationGelderland(
			{
				variables: {
					locale,
					publicationState
				}
			},
			client
		);
		setPageInformation(result.props.data.pageInformation?.data?.attributes);
	}
};

export default NotFoundPage;
