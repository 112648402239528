import { ButtonFieldsFragment } from 'api/graphql-types';

export interface IGetStrapiButtonIconProps {
	url?: string;
	position?: 'left' | 'right';
}

export const getStrapiButtonIconProps = (button?: ButtonFieldsFragment): IGetStrapiButtonIconProps => ({
	url: button?.iconLeft?.data?.attributes?.url || button?.iconRight?.data?.attributes?.url,
	position: button?.iconLeft?.data?.attributes?.url
		? 'left'
		: button?.iconRight?.data?.attributes?.url
		? 'right'
		: undefined
});
