import clsx from 'clsx';

import { LinkBoxFieldsFragment } from 'api/graphql-types';

import { Icon } from 'components/icon/icon';
import { Button, IButtonVariants } from 'components/button';
import { Typography } from 'components/typography';
import UrlToSvg from 'components/url-to-svg/url-to-svg';

import styles from './linkbox.module.scss';

export interface ILinkboxProps {
	module: LinkBoxFieldsFragment;
}

const Linkbox = (props: ILinkboxProps): React.JSX.Element => {
	const background = props.module?.defaultModuleOptions?.extendedBackgroundColor;

	return (
		<div className={clsx(styles.Linkbox, background === 'darkblue' && styles.dark)}>
			{props.module?.title?.value && (
				<Typography tag={props.module.title.tag || 'div'} className={styles.title}>
					{props.module.title.value}
				</Typography>
			)}

			{props.module?.description && <div className={styles.description}>{props.module.description}</div>}
			{props.module?.linkBoxLinks?.length && (
				<div className={styles.links}>
					{props.module.linkBoxLinks.map((link, idx) => {
						let buttonVariant: string = link?.variant as string;
						if (background === 'darkblue' && (link?.variant === 'primary' || link?.variant === 'secondary')) {
							buttonVariant = `${link?.variant}-light`;
						}
						if (background !== 'darkblue' && link?.variant === 'tertiary') {
							buttonVariant = `${link?.variant}-dark`;
						}

						return (
							<span className={styles.button} key={idx}>
								<Button
									fullWidth
									variant={buttonVariant as unknown as IButtonVariants}
									title={link?.href?.text || ''}
									href={link?.href?.url || '#'}
									icon={
										link?.iconLeft?.data || link?.iconRight?.data ? (
											<Icon
												customIcon={
													<UrlToSvg
														url={
															(link?.iconLeft?.data?.attributes?.url as string) ||
															(link?.iconRight?.data?.attributes?.url as string)
														}
													/>
												}
												size="14px"
											/>
										) : undefined
									}
									iconPosition={link?.iconLeft ? 'left' : link?.iconRight ? 'right' : undefined}
								/>
							</span>
						);
					})}
				</div>
			)}
		</div>
	);
};

export default Linkbox;
