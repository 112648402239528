export const ROUTES = {
	DOWNLOADS: {
		fullPath: 'Downloads',
		slug: 'downloads'
	},
	WORK_AT: {
		fullPath: 'Werken bij de provincie',
		slug: 'werken-bij-de-provincie'
	},
	CONTACT: {
		fullPath: 'Contact',
		slug: 'contact'
	},
	SITEMAP: {
		fullPath: 'Sitemap',
		slug: 'sitemap'
	},
	PRIVACY_POLICY: {
		fullPath: 'Privacyverklaring',
		slug: 'privacyverklaring'
	},
	HOME: {
		fullPath: 'Home',
		slug: ''
	},
	COOKIES: {
		fullPath: 'Cookies',
		slug: 'cookies'
	},
	WOB_REQUESTS: {
		fullPath: 'WOB-verzoeken',
		slug: 'wob-verzoeken'
	},
	WOO_REQUESTS: {
		fullPath: 'WOO-verzoeken',
		slug: 'woo-verzoeken'
	},
	ROAD_MAINTENANCES: {
		fullPath: 'Wegwerkzaamheden',
		slug: 'wegwerkzaamheden'
	},
	PERMITS: {
		fullPath: 'Vergunningen',
		slug: 'vergunningen'
	},
	REGIONS: {
		fullPath: "Regio's",
		slug: 'regios'
	},
	THEMES: {
		fullPath: "Thema's",
		slug: 'themas'
	},
	SUBSIDIES: {
		fullPath: 'Subsidies',
		slug: 'subsidies'
	},
	PRESS_RELEASES: {
		fullPath: 'Persberichten',
		slug: 'persberichten'
	},
	NEWS: {
		fullPath: 'Nieuws',
		slug: 'nieuws'
	},
	NEWS_ARTICLES: {
		fullPath: 'Nieuwsberichten',
		slug: 'nieuwsberichten'
	},
	EVENTS: {
		fullPath: 'Evenementen',
		slug: 'evenementen'
	},
	MAPS_AND_FIGURES: {
		fullPath: 'Kaarten & cijfers',
		slug: 'kaarten-en-cijfers'
	},
	NOT_FOUND: {
		fullPath: '404',
		slug: 'kaarten-en-cijfers'
	},
	PROJECTS: {
		fullPath: 'Projecten',
		slug: 'projecten'
	},
	SEARCH_OVERVIEW: {
		fullPath: 'Zoeken',
		slug: 'zoeken'
	},
	AGENDA: {
		fullPath: 'Agenda',
		slug: 'agenda'
	},
	DECISIONS: {
		fullPath: 'Besluiten',
		slug: 'besluiten'
	},
	NEWSLETTER_PUBLIC: {
		fullPath: 'Nieuwsbrief',
		slug: 'nieuwsbrief'
	},
	NEWSLETTER_INTERNAL: {
		fullPath: 'Nieuwsbrief intern',
		slug: 'nieuwsbrief-intern'
	},
	NEWSLETTER_ARCHIVE: {
		fullPath: 'Nieuwsbrief archief',
		slug: 'nieuwsbrief-archief'
	},
	PASSWORD: {
		fullPath: 'Wachtwoord',
		slug: ''
	},
	IN_CONVO_WITH: {
		fullPath: 'In Gesprek Met',
		slug: 'in-gesprek-met'
	},
	VACANCIES: {
		fullPath: 'vacatures',
		slug: 'vacatures'
	},
	VACANCIES_INTERNAL: {
		fullPath: 'vacatures/intern',
		slug: 'vacatures/intern'
	}
};

export const EFRO_OOST_ROUTES = {
	...ROUTES,
	IN_CONVO_WITH: {
		fullPath: 'In Gesprek met',
		slug: 'in-gesprek-met'
	}
};

export const REGIO_EXPRESS_ROUTES = {
	...ROUTES,
	IN_CONVO_WITH: {
		fullPath: 'In Gesprek met',
		slug: 'in-gesprek-met'
	}
};

export const OP_OOST_ROUTES = {
	...ROUTES,
	NEWS: {
		fullPath: 'Nieuwsberichten',
		slug: 'nieuwsberichten'
	},
	IN_CONVO_WITH: {
		fullPath: 'In Gesprek met',
		slug: 'in-gesprek-met'
	}
};

export const OPEN_GELDERLAND_ROUTES = {
	WOO_DOCUMENTS: {
		fullPath: 'WOO-Documenten',
		slug: 'woo-documenten'
	}
};
