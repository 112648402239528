import { useEffect, useState } from 'react';
import clsx from 'clsx';
import ReactPlayer from 'react-player';

import { CtaFieldsFragment } from 'api/graphql-types';
import { Button, IButtonVariants } from 'components/button';
import Image from 'components/image';
import UrlToSvg from 'components/url-to-svg/url-to-svg';

import { Icon } from 'components/icon/icon';
import { getStrapiButtonIconProps } from 'utils/get-strapi-button-icon';

import styles from './cta.module.scss';

export interface ICtaProps {
	module: CtaFieldsFragment;
}

const Cta = (props: ICtaProps): React.JSX.Element => {
	const { module } = props;
	const { videoLink } = module;

	const imageOrVideoFile = module.imageOrVideoFile?.data?.attributes;

	const [mediaLink, setMediaLink] = useState<string>();
	const [videoUrl, setVideoUrl] = useState<string>('');
	const [isAudio, setIsAudio] = useState<boolean>(false);
	const [isVideo, setIsVideo] = useState<boolean>(false);

	const [isSSR, setIsSSR] = useState(true);

	useEffect(() => {
		setIsSSR(false);
	}, []);

	useEffect(() => {
		const link =
			videoLink?.includes('youtu.be') || videoLink?.includes('youtube.com')
				? `https://www.youtube-nocookie.com/embed/${
						videoLink?.split('watch?v=')[1] || videoLink?.split('youtu.be/')[1]
				  }`
				: videoLink;

		setMediaLink(link);

		const video = imageOrVideoFile?.url || mediaLink || videoLink || '';

		setVideoUrl(video);

		setIsAudio(imageOrVideoFile && imageOrVideoFile?.mime.toLowerCase().indexOf('audio') > -1 ? true : false);
	}, []);

	useEffect(() => {
		if (ReactPlayer.canPlay(videoUrl)) {
			setIsVideo(true);
		} else {
			setIsVideo(false);
		}
	}, [videoUrl]);

	const background = module.defaultModuleOptions?.extendedBackgroundColor;

	const showMedia = (): React.JSX.Element => {
		if (!isSSR) {
			return (
				<>
					{(imageOrVideoFile || videoLink) && (
						<div className={clsx(styles.image, module.imagePosition === 'right' && styles.rightImage)}>
							{imageOrVideoFile?.mime.includes('image') ? (
								<Image
									attributes={imageOrVideoFile}
									style={{ objectFit: 'cover' }}
									sizes="(max-width: 560px) 520px, (max-width: 960px) 912px, 560px"
									imageSize="large"
									fill
								/>
							) : isVideo ? (
								<ReactPlayer
									width="740px"
									height={isAudio ? '19px' : '296px'}
									url={videoUrl}
									controls
									config={{
										youtube: { playerVars: { disablekb: 1, rel: 0 } }
									}}
								/>
							) : (
								<iframe
									title="Video"
									width="100%"
									height="100%"
									src={videoUrl}
									frameBorder="0"
									allow="autoplay; fullscreen; picture-in-picture"
									allowFullScreen
								/>
							)}
						</div>
					)}
				</>
			);
		}
		return <></>;
	};

	return (
		<div
			className={clsx(
				styles.ctaModule,
				background === 'darkblue' && styles.dark,
				!(imageOrVideoFile || videoLink) && styles.noMedia
			)}
		>
			<div className={styles.moduleContent}>
				<div className={styles.content}>
					{(module.ctaTitle?.value || module.description) && (
						<div className={styles.text}>
							{module.ctaTitle?.value && <h2>{module.ctaTitle.value}</h2>}
							{module.description && <span className={styles.description}>{module.description}</span>}
						</div>
					)}
					{module.buttons && module.buttons.length > 0 && (
						<div className={styles.buttons}>
							{module.buttons.map((button, index) => {
								const { url, position } = getStrapiButtonIconProps(button);
								let buttonVariant: string = button?.variant as string;
								if (background === 'darkblue' && (button?.variant === 'primary' || button?.variant === 'secondary')) {
									buttonVariant = `${button?.variant}-light`;
								}
								if (background !== 'darkblue' && button?.variant === 'tertiary') {
									buttonVariant = `${button?.variant}-dark`;
								}

								return (
									<div className={styles.link} key={index}>
										<Button
											variant={buttonVariant as unknown as IButtonVariants}
											title={button?.href?.text}
											fullWidth
											href={button?.href?.url || '#'}
											icon={url && <Icon customIcon={<UrlToSvg url={url} />} size="14px" />}
											iconPosition={position}
										/>
									</div>
								);
							})}
						</div>
					)}
				</div>
				<>{showMedia()}</>
			</div>
		</div>
	);
};

export default Cta;
