import { useEffect, useState } from 'react';

interface IProps {
	url: string;
}

const UrlToSvg = ({ url }: IProps): React.JSX.Element => {
	const [svg, setSVG] = useState<string>();

	useEffect(() => {
		if (url?.endsWith('.svg')) {
			fetch(url)
				.then((res) => res.text())
				.then((text) => setSVG(text))
				.catch(() => setSVG(undefined));
		}
	}, []);

	if (!svg) {
		return <></>;
	}

	// eslint-disable-next-line react/no-danger
	return <div dangerouslySetInnerHTML={{ __html: svg }} />;
};

export default UrlToSvg;
